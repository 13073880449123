import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Hero from "../components/Hero";
import Section from "../components/Section";
import { getService } from "../data";

const images = process.env.PUBLIC_URL + "/assets/images";
const Service = () => {
  let params = useParams();
  let service = getService(params.category);
  const navigate = useNavigate();
  console.log({ service });
  return (
    <>
      <div className="service-hero">
        <Hero
          isCategory={true}
          title={service.category}
          subtitle1={service.subtitle1}
          subtitle2={service.subtitle2}
          description={service.description}
          photoURL={service.photoURL}
          videoURL={service.videoURL}
        />
      </div>
      <div className={`row g-3 rounded-3  m-5`}>
          {service.projects.map((project, idx) => (
            <div key={idx} className="col-lg-4 p-0 border position-relative">
                <div className="project-card" onClick={() => navigate(`/projects/${project.title}`)}>
                {project.photoURL !== undefined ? <img
                    src={images + project.photoURL}
                    alt=""
                    width={"100%"}
                    height={"600px"}
                    style={{ objectFit: "cover" }}
                    /> : null
                }
                    {project.videoURL !== undefined ? <iframe src="https://player.vimeo.com/video/759162634" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe> : null}
                    <div className="position-absolute gradient w-100 bottom-0 start-25">
                    <p className="p-2 text-white">{project.title}</p>
                    </div>
                </div>
            </div>
          ))}
      </div>
      {/*{service.projects.map((project, idx) => (
                <Section key={idx} bg={images + project.photoURL} isProject={true} title={project.title} desc={project.description} />
            ))}*/}
    </>
  );
};

export default Service;
