import { motion, useAnimation } from "framer-motion"
import constants from "./utils/constants"
import { useInView } from "react-intersection-observer"
import { useEffect } from "react"
import { Link, useParams } from "react-router-dom"

export default function Hero({ title, subtitle1, subtitle2, sub, photoURL, videoURL, description, isHome = false, isProject = false, isCategory = false }) {
    let {category} = useParams();
    const images = process.env.PUBLIC_URL + "/assets/images"
    const videos = process.env.PUBLIC_URL + "/assets/video"
    const line1 = "Creating"
    const line2 = "High Quality Product Animations"
    const controls = useAnimation()
    const [ref, inView] = useInView()
    useEffect(() => {
        if (inView) {
            controls.start("animate");
        }
    }, [controls, inView]);

    return (
        <>
            {isCategory &&
                <motion.div className="container py-5" variants={constants.hero} initial="initial" animate="animate">
                    <div className="py-5 mb-4 hero">
                        <div className="row mb-5 pt-5 mt-2">
                            <div className="col-lg-8">
                                <img src={images + "/hero/bar.png"} alt="hero-bar" height="14px" />
                                <motion.h1 variants={constants.heading}>
                                    {subtitle1.split("").map((char, index) => {
                                        return (
                                            <motion.span key={char + "-" + index} variants={constants.letter}>
                                                {char}
                                            </motion.span>
                                        )
                                    })}
                                    <span className="linear">
                                        {subtitle2.split("").map((char, index) => {
                                            return (
                                                <motion.span key={char + "-" + index} variants={constants.letter}>
                                                    {char}
                                                </motion.span>
                                            )
                                        })}
                                    </span>
                                </motion.h1>
                                <h3 className="mt-3">{description}</h3>
                                
                            </div>
                            <div className="col-lg-4">
                                {photoURL && <img src={images + photoURL} alt="hero-img" width={'100%'} height={'100%'} />}
                                {videoURL && <video width={"100%"} height={"100%"} muted loop autoPlay>
                                <source src={videos + videoURL} type="video/mp4"/>
                              </video>}
                            </div>
                        </div>
                    </div>
                </motion.div>
            }
            {isHome &&
                <motion.div className="container py-5" variants={constants.hero} initial="initial" animate="animate">
                    <div className="py-5 mb-4 hero">
                        <div className="row mb-5 pt-5 mt-2">
                            <div className="col-lg-7">
                                <img src={images + "/hero/bar.png"} alt="hero-bar" height="14px" />
                                <motion.h1 variants={constants.heading}>
                                    {line1.split("").map((char, index) => {
                                        return (
                                            <motion.span key={char + "-" + index} variants={constants.letter}>
                                                {char}
                                            </motion.span>
                                        )
                                    })}
                                    <span className="linear">
                                        {line2.split("").map((char, index) => {
                                            return (
                                                <motion.span key={char + "-" + index} variants={constants.letter}>
                                                    {char}
                                                </motion.span>
                                            )
                                        })}
                                    </span>
                                </motion.h1>
                                <h3 className="mt-3">
                                Attract more customers using stunning product animations and photorealistic 3D renders.
                                </h3>
                            </div>
                            <div className="col-lg-5">
                                <img src={images + "/hero/rotate.gif"} alt="hero-img" width={'100%'} height={'100%'} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="rounded-4 border-success p-4 bg-white">
                                    <div className="d-flex">
                                        <p>Tons of <strong>Brands</strong> trust our works, <br />you can too</p>
                                        <span className="divider ">/</span>
                                        <div className="pt-3 d-flex flex-wrap w-100 justify-content-between items-center">
                                            <img className="mx-2 w-30" height={"20px"} src={images + "/brands/Logos/soenlogo.png"} alt="soenlogo" />
                                            <img className="mx-2 w-30" height={"20px"}  src={images + "/brands/Logos/Branding_Logo_Black.png"} alt="Branding_Logo_Black" />
                                            <img className="mx-2 w-30" height={"30px"}  src={images + "/brands/Logos/fayre.jpeg"} alt="fayre" />
                                            <img className="mx-2 w-30" height={"40px"}  src={images + "/brands/Logos/Muvro.png"} alt="Muvro" />
                                            <img className="mx-2 w-30" height={"40px"}  src={images + "/brands/Logos/Artboard.png"} alt="Artboard" />                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </motion.div>
            }
            {isProject &&
                    <motion.div className="container-fluid hero sub py-5 text-center" variants={constants.hero} initial="initial" animate="animate">
                        <div className="py-5 my-5">
                            <div className="py-5"></div>
                            <motion.h1 className="mt-5 pt-5" variants={constants.heading}>
                                <span className="linear">
                                    {title.split("").map((char, index) => {
                                        return (
                                            <motion.span key={char + "-" + index} variants={constants.letter}>
                                                {char}
                                            </motion.span>
                                        )
                                    })}
                                </span>
                            </motion.h1>
                            <div className="col-lg-6 mx-auto">
                                <p className="text-white">{description}</p>
                            </div>
                        </div>
                    </motion.div>
            }
        </>
    )
}
