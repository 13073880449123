import Header from "../components/Header"
import Footer from "../components/Footer"
import Hero from "../components/Hero"
import Services from "../components/Services"
import Testimonials from "../components/Testimonials"
import About from "../components/About"
import Reel from "../components/Reel"
import Subscription from "../components/Subscription"

export default function Home() {
    return (
        <div className="home-page">
            <Hero isHome={true} />
            <Reel />
            <Subscription/>
            <Services/>
            <About />
            <Testimonials />
        </div>
    )
}
