// add comment
export const ServiceList = [
    {
        category: "Photorealistic Product Images",
        subtitle1: "Photorealistic",
        subtitle2: "Product Images",
        photoURL: "/services/services.png",
        description: "Display your products using stunning CGI rendered images with amazing looks and angles that just make your products look appealing and stand out from competition",
        projects: [
            {
                title: "Inspiration Drink",
                photoURL: "/services/product-render/inspiration-drink/render2.jpg",
                description: "Top notch photorealistic 3D renderings definitely grab your audience's attention",
                details: [
                    {
                        heading: "Slide 1",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/inspiration-drink/render2.jpg"
                    },
                    {
                        heading: "Slide 2",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/inspiration-drink/render3.jpg"
                    },
                    {
                        heading: "Slide 3",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/inspiration-drink/render4.jpg"
                    },
                    {
                        heading: "Slide 4",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/inspiration-drink/render5.jpg"
                    },
                    {
                        heading: "Slide 3",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/inspiration-drink/render8.jpg"
                    }
                ]
            },
            {
                title: "Almandoos sandal",
                photoURL: "/services/product-render/almandoos sandal/almandoos footware.png",
                description: "With good looking effects like exploded views, x ray views and different simulations, we can express your products in ways that aren’t possible with traditional videography tools",
                details: [
                    {
                        heading: "Slide 1",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/almandoos sandal/almandoos footware.png"
                    },
                    {
                        heading: "Slide 2",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/almandoos sandal/test4.png"
                    },
                    {
                        heading: "Slide 3",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/almandoos sandal/top.png"
                    }
                ]
            },
            {
                title: "Jack Daniels",
                photoURL: "/services/product-render/jack daniels/render2.jpg",
                description: "With good looking effects like exploded views, x ray views and different simulations, we can express your products in ways that aren’t possible with traditional videography tools",
                details: [
                    {
                        heading: "Slide 1",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/jack daniels/render2.jpg"
                    },
                    {
                        heading: "Slide 2",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/jack daniels/render3.jpg"
                    },
                    {
                        heading: "Slide 3",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/jack daniels/render4.jpg"
                    },
                    {
                        heading: "Slide 4",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/jack daniels/render5.jpg"
                    },
                    {
                        heading: "Slide 5",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/jack daniels/render6.jpg"
                    },
                    {
                        heading: "Slide 6",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/jack daniels/render7.jpg"
                    }
                ]
            },
            {
                title: "Lind and Lime Gin",
                photoURL: "/services/product-render/Lind and Lime Gin/image-render-1.jpg",
                description: "With good looking effects like exploded views, x ray views and different simulations, we can express your products in ways that aren’t possible with traditional videography tools",
                details: [
                    {
                        heading: "Slide 1",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/Lind and Lime Gin/image-render-1.jpg"
                    },
                    {
                        heading: "Slide 2",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/Lind and Lime Gin/image-render-2.jpg"
                    },
                    {
                        heading: "Slide 3",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/Lind and Lime Gin/image-render-3.jpg"
                    }
                ]
            },
            {
                title: "Nature Gin",
                photoURL: "/services/product-render/Nature Gin/nature-gin-front-render.png",
                description: "With good looking effects like exploded views, x ray views and different simulations, we can express your products in ways that aren’t possible with traditional videography tools",
                details: [
                    {
                        heading: "Slide 1",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/Nature Gin/nature-gin-front.png"
                    },
                    {
                        heading: "Slide 2",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/Nature Gin/nature-gin-front-render.png"
                    },
                    {
                        heading: "Slide 3",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/Nature Gin/nature-gin-side-render.png"
                    },
                    {
                        heading: "Slide 4",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/Nature Gin/nature-gin-side.png"
                    }
                ]
            },
            {
                title: "Nikon Z6",
                photoURL: "/services/product-render/nikon z6/cam1.jpg",
                description: "With good looking effects like exploded views, x ray views and different simulations, we can express your products in ways that aren’t possible with traditional videography tools",
                details: [
                    {
                        heading: "Slide 1",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/nikon z6/cam1.jpg"
                    },
                    {
                        heading: "Slide 2",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/nikon z6/cam2.jpg"
                    },
                    {
                        heading: "Slide 3",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/nikon z6/cam3.jpg"
                    },
                    {
                        heading: "Slide 4",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/nikon z6/camera_shot.jpg"
                    },
                    {
                        heading: "Slide 5",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/nikon z6/camera_view.jpg"
                    },
                    {
                        heading: "Slide 6",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/nikon z6/scene_view.jpg"
                    }
                ]
            },
            {
                title: "Sense glove",
                photoURL: "/services/product-render/sense-glove/hold_visible.png",
                description: "With good looking effects like exploded views, x ray views and different simulations, we can express your products in ways that aren’t possible with traditional videography tools",
                details: [
                    {
                        heading: "Slide 1",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/sense-glove/render_strap1.png"
                    },
                    {
                        heading: "Slide 2",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/sense-glove/shot1.png"
                    },
                    {
                        heading: "Slide 3",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/sense-glove/hold_visible.png"
                    },
                    {
                        heading: "Slide 4",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/sense-glove/shot2.png"
                    },
                    {
                        heading: "Slide 5",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/sense-glove/squeeze.png"
                    }
                ]
            },
            {
                title: "Ethy Cosmetics",
                photoURL: "/services/product-render/Ethy/bathroomscene1.jpg",
                description: "With good looking effects like exploded views, x ray views and different simulations, we can express your products in ways that aren’t possible with traditional videography tools",
                details: [
                    {
                        heading: "Slide 1",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/Ethy/bathroomscene1.jpg"
                    },
                    {
                        heading: "Slide 2",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/Ethy/bodymilk_flowerflat.jpg"
                    },
                    {
                        heading: "Slide 3",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/Ethy/bodymilk.jpg"
                    },
                    {
                        heading: "Slide 4",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/Ethy/cloth scene.jpg"
                    },
                    {
                        heading: "Slide 5",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/Ethy/ethy_splash.jpg"
                    }
                ]
            },
            {
                title: "Surfboard",
                photoURL: "/services/product-render/surfboard/inflatable_surf_board_blue.jpg",
                description: "With good looking effects like exploded views, x ray views and different simulations, we can express your products in ways that aren’t possible with traditional videography tools",
                details: [
                    {
                        heading: "Slide 1",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/surfboard/inflatable_surf_board_blue.jpg"
                    },
                    {
                        heading: "Slide 2",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/surfboard/inflatable_surf_board_pink.jpg"
                    },
                    {
                        heading: "Slide 3",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/surfboard/inflatable_surf_board.jpg"
                    }
                ]
            },
            {
                title: "Bassier Closet",
                photoURL: "/services/product-render/bassier closet/blue_version_.jpg",
                description: "With good looking effects like exploded views, x ray views and different simulations, we can express your products in ways that aren’t possible with traditional videography tools",
                details: [
                    {
                        heading: "Slide 1",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/bassier closet/blue_version_.jpg"
                    },
                    {
                        heading: "Slide 2",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/bassier closet/intrior.jpg"
                    },
                    {
                        heading: "Slide 3",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/bassier closet/open_view.jpg"
                    },
                    {
                        heading: "Slide 2",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/bassier closet/render1.jpg"
                    },
                    {
                        heading: "Slide 3",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/bassier closet/white_version_.jpg"
                    }
                ]
            },
            {
                title: "Headphone",
                photoURL: "/services/product-render/headphone/Headset render1.png",
                description: "With good looking effects like exploded views, x ray views and different simulations, we can express your products in ways that aren’t possible with traditional videography tools",
                details: [
                    {
                        heading: "Slide 1",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/headphone/Headset render1.png"
                    },
                    {
                        heading: "Slide 2",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/headphone/Headset render2.png"
                    },
                    {
                        heading: "Slide 3",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/headphone/Headset render4.png"
                    },
                    {
                        heading: "Slide 2",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/headphone/Headset render7.png"
                    },
                    {
                        heading: "Slide 3",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/headphone/Headset render9.png"
                    },
                    {
                        heading: "Slide 2",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/headphone/Headset render10.png"
                    },
                    {
                        heading: "Slide 2",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/headphone/Headset render12.png"
                    }
                ]
            },
            {
                title: "Engine Manifold",
                photoURL: "/services/product-render/engine manifold/final_truck_engine.jpg",
                description: "With good looking effects like exploded views, x ray views and different simulations, we can express your products in ways that aren’t possible with traditional videography tools",
                details: [
                    {
                        heading: "Slide 1",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/engine manifold/final_truck_engine.jpg"
                    },
                    {
                        heading: "Slide 2",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/engine manifold/final_truck_render.jpg"
                    },
                    {
                        heading: "Slide 3",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/engine manifold/final_truck_transparent_engine.jpg"
                    },
                    {
                        heading: "Slide 3",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/engine manifold/manifold_render.jpg"
                    }
                ]
            },
            {
                title: "Furnitures",
                photoURL: "/services/product-render/furniture/Short_chair.jpg",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae mauris bibendum, ornare tortor nec, sollicitudin libero.",
                details: [
                    {
                        heading: "Slide 1",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/furniture/Short_chair.jpg"
                    },
                    {
                        heading: "Slide 2",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/furniture/Short_chair1.jpg"
                    },
                    {
                        heading: "Slide 3",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/furniture/Short_chair2.jpg"
                    },
                    {
                        heading: "Slide 4",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/furniture/tall_chair.jpg"
                    },
                    {
                        heading: "Slide 5",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        photoURL: "/services/product-render/furniture/tall_chair2.jpg"
                    }
                    
                ]
            }
        ]
    },
    {
        category: "Never looked better",
        hideButton: true,
        subtitle1: "Never",
        subtitle2: "Looked Better",
        photoURL: "/services/nature-gin-render.png",
        description: "Discover new creative ways to present your products",
        projects: []
    },
    {
        category: "Product Animation",
        subtitle1: "Product",
        subtitle2: "Animation",
        videoURL: "/productanimatioshotfirst(1).mp4",
        description: "We help you creatively convey the features and value of your product to your customers with High quality product animations",
        projects: [
            {
                title: "Ikea installation",
                photoURL: "/services/product-animation/Ikea-installation/ikea.gif",
                description: "We help you creatively convey the features and value of your product to your customers with High quality product animations",
                details: [
                    {
                        heading: "Slide 1",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        videoURL: "https://player.vimeo.com/video/657008438",
                        
                    },
                ]
            },
            {
                title: " Nikon Camera",
                photoURL: "/services/product-animation/Nikon-Camera/NIkon.gif",
                description: "We help you creatively convey the features and value of your product to your customers with High quality product animations",
                details: [
                    {
                        heading: "Slide 1",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        videoURL: "https://player.vimeo.com/video/653981298",
                    }
                ]
            },
            {
                title: "Handscrl",
                photoURL: "/services/product-animation/handscrl.gif",
                description: "We help you creatively convey the features and value of your product to your customers with High quality product animations",
                details: [
                    {
                        heading: "Slide 1",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        videoURL: "https://player.vimeo.com/video/632202611",
                    }
                ]
            },
            {
                title: "Naija Hair",
                photoURL: "/services/product-animation/naijahair.gif",
                description: "We help you creatively convey the features and value of your product to your customers with High quality product animations",
                details: [
                    {
                        heading: "Slide 1",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        videoURL: "https://player.vimeo.com/video/523799123",
                    }
                ]
            },
            {
                title: "Brazzir",
                photoURL: "/services/product-animation/brazzir.gif",
                description: "We help you creatively convey the features and value of your product to your customers with High quality product animations",
                details: [
                    {
                        heading: "Slide 1",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        videoURL: "https://player.vimeo.com/video/523798015",
                    }
                ]
            },
            {
                title: "Anti Theft",
                photoURL: "/services/product-animation/antitheft.gif",
                description: "We help you creatively convey the features and value of your product to your customers with High quality product animations",
                details: [
                    {
                        heading: "Slide 1",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        videoURL: "https://player.vimeo.com/video/523799365",
                    }
                ]
            },
            {
                title: "Headset",
                photoURL: "/services/product-animation/headset.gif",
                description: "We help you creatively convey the features and value of your product to your customers with High quality product animations",
                details: [
                    {
                        heading: "Slide 1",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        videoURL: "https://player.vimeo.com/video/777691846 ",
                    }
                ]
            },
            {
                title: "Chevy",
                photoURL: "/services/product-animation/chevy.gif",
                description: "We help you creatively convey the features and value of your product to your customers with High quality product animations",
                details: [
                    {
                        heading: "Slide 1",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        videoURL: "https://player.vimeo.com/video/778038629 ",
                    }
                ]
            },
            {
                title: "Inspiration",
                photoURL: "/services/product-animation/inspiration.gif",
                description: "We help you creatively convey the features and value of your product to your customers with High quality product animations",
                details: [
                    {
                        heading: "Slide 1",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        videoURL: "https://player.vimeo.com/video/778040170",
                    }
                ]
            },
            {
                title: "Nike Air Max (ankara)",
                photoURL: "/services/product-animation/Ankara_max advert.gif",
                description: "We help you creatively convey the features and value of your product to your customers with High quality product animations",
                details: [
                    {
                        heading: "Slide 1",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        videoURL: "https://player.vimeo.com/video/778037033",
                    }
                ]
            },
            {
                title: "Lamborghini Terzo Millennio",
                photoURL: "/services/product-animation/terzo.gif",
                description: "We help you creatively convey the features and value of your product to your customers with High quality product animations",
                details: [
                    {
                        heading: "Slide 1",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        videoURL: "https://player.vimeo.com/video/778039467",
                    }
                ]
            },
            {
                title: "Cidar Phone",
                photoURL: "/services/product-animation/cidarphone.gif",
                description: "We help you creatively convey the features and value of your product to your customers with High quality product animations",
                details: [
                    {
                        heading: "Slide 1",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        videoURL: "https://player.vimeo.com/video/778037789",
                    }
                ]
            },
            {
                title: "HVAC",
                photoURL: "/services/product-animation/hvac.gif",
                description: "We help you creatively convey the features and value of your product to your customers with High quality product animations",
                details: [
                    {
                        heading: "Slide 1",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        videoURL: "https://player.vimeo.com/video/756943318",
                    }
                ]
            },
            {
                title: "Bridge construction",
                photoURL: "/services/product-animation/beams.gif",
                description: "We help you creatively convey the features and value of your product to your customers with High quality product animations",
                details: [
                    {
                        heading: "Slide 1",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        videoURL: "https://player.vimeo.com/video/552963214",
                    }
                ]
            },
            {
                title: "Earbuds Animation",
                photoURL: "/services/product-animation/earbud.gif",
                description: "We help you creatively convey the features and value of your product to your customers with High quality product animations",
                details: [
                    {
                        heading: "Slide 1",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        videoURL: "https://player.vimeo.com/video/777692927",
                    }
                ]
            },
            {
                title: "Iphone Case",
                photoURL: "/services/product-animation/phonecase.gif",
                description: "We help you creatively convey the features and value of your product to your customers with High quality product animations",
                details: [
                    {
                        heading: "Slide 1",
                        info: "good looking effects like exploded views, x ray views and different simulations.",
                        videoURL: "https://player.vimeo.com/video/777692415",
                    }
                ]
            },
        ]
    },
    {
        category: "Clever Display",
        hideButton: true,
        subtitle1: "Clever",
        subtitle2: "Display",
        videoURL: "/productaniamtioneffects (1).mp4",
        description: "Use effects and break apart animations to show the individual and interior parts",
        projects: []
    }
]

export function getService(category) {
    return ServiceList.find(
        (service) => service.category === category
    );
}

export function getProjectByTitle(title) {
    const projects = ServiceList.flatMap(service => service.projects);

    return projects.find(project => project.title === title);
    // return ServiceList.map((service) => service.projects.find(project => project.title === title));
}