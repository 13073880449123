import {Routes, Route} from 'react-router-dom'
import Layout from './Layout';
import Home from './pages/Home';
import Projects from './pages/Projects';
import Project from './routes/Project';
import Service from './routes/Service';
import About from "./components/About"
function App() {
  return (
    <>
    <Routes>
    <Route element={<Layout/>}>
      <Route path='/' element={<Home />} />
      <Route path='/projects' element={<Projects />} />
      <Route path='/about' element={<About />} />
      <Route path="/services/:category" element={<Service />} />
      <Route path="/projects/:projectTitle" element={<Project />} />
    </Route>
    </Routes>
    </>
  );
}

export default App;
