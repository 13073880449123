import React from 'react'
const images = process.env.PUBLIC_URL + "/assets/images"

const Gallery = ({details}) => {
    return (
        <div class="container-fluid">
            <div class="row gy-4 m-4 justify-content-md-center">
            {details.map(({photoURL, videoURL, heading}, idx) => (
                <>
                {photoURL && <div key={idx} class="col-8">
                    {photoURL && <img src={images + photoURL} alt="" width={'100%'} height={'100%'} style={{objectFit: "cover"}} />}
                </div>}
                {videoURL && <div key={idx} class="col-12">
                    {videoURL && <iframe title={heading} src={videoURL} frameborder="0" allowfullscreen></iframe>}
                </div>

                }
                </>
                
            ))}
            </div>
        </div>
    )
}

export default Gallery