import Section from "./Section"
import { motion, useAnimation } from "framer-motion"
import constants from "../components/utils/constants"
import { useInView } from "react-intersection-observer"
import { useEffect, useState } from "react"
import ServiceHeader from "../components/ServiceHeader"
import { ServiceList } from "../data"

export default function Services({service}) {
    const [serviceListData, setServiceListData] = useState([])
    const controls = useAnimation()
    const [ref, inView] = useInView()
    useEffect(() => {
        if (inView) {
            controls.start("animate");
        }
    }, [controls, inView]);
    useEffect(()=> {
        setServiceListData(ServiceList)
    }, [])

    // const description = "We help companies sell their products by attracting more customers using stunning product animations and photorealistic 3D renders"
    

    return (
        <div id="services">
        <motion.div className="container py-5 mt-5 bg-white rounded-4"
                variants={constants.slideUp}
                ref={ref}
                initial="initial"
                animate={controls}
            >
            <ServiceHeader/>
                {serviceListData.map((service, idx) => (
                    <Section key={idx} bg={service.photoURL} vid={service.videoURL} isService={true} title={service.category} desc={service.description} hideButton={service.hideButton} />
                ))}
            </motion.div>
        </div>
    )
}
