import { motion } from "framer-motion";
import constants from "./utils/constants";
import { FaBehance, FaLinkedin, FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";

export default function Footer() {
  const images = process.env.PUBLIC_URL + "/assets/images";
  return (
    <>
      <div className="container rounded-5 footer pt-5 pb-2 my-5 text-center">
        <div className="mt-5 pt-3 mb-3">
          <h1 className="text-center text-white">Want to start a project?</h1>
        </div>
        <motion.a
          className="btn btn-lg cta btn-success nav-btn  py-3 px-5"
          href={"mailto:contact@cidarstudios.com"}
          whileHover={{
            scale: 1.1,
            boxShadow: "0px 0px 8px #fff",
            transition: { type: "spring", stiffness: 320 },
          }}
        >
          Reach out to us
          <img src={images + "/footer-img.png"} alt="" width={"35px"} />
        </motion.a>
        <div className="mt-4 d-flex justify-content-center">
          <small className="text-secondary">
            Copyright &copy; CIDAR, {new Date().getFullYear()}. All rights
            reserved
          </small>
        </div>
      </div>
    </>
  );
}
