import React from 'react'
import { useParams } from "react-router-dom";
import Gallery from '../components/Gallery';
import Hero from '../components/Hero';
import Section from '../components/Section';
import { getProjectByTitle } from '../data';

const images = process.env.PUBLIC_URL + "/assets/images"
const Project = () => {
    let params = useParams();
    let project = getProjectByTitle(params.projectTitle);
    console.log({project})
    return (
        <div>
            <div className='service-hero'>
                <Hero isProject={true} title={project.title} description={project.description} photoURL={project.photoURL} videoURL={project.videoURL} />
            </div>
            <Gallery details={project.details}/>
            {/*{project.details.map((detail, idx) => (
                <Section key={idx} bg={images + detail.photoURL} isProject={true} isProjectDetail={true} title={detail.heading} desc={detail.info} />
            ))}*/}
        </div>
    )
}

export default Project