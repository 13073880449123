import { NavLink, Router, useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Link, animateScroll as scroll } from "react-scroll";

export default function Header({ transparent }) {
  const location = useLocation();

  const navigate = useNavigate();

  const images = process.env.PUBLIC_URL + "/assets/images";
  let initialImage;
  if (transparent) {
    initialImage = images + "/logos/type-black.png";
  } else {
    initialImage = images + "/logos/type-white.png";
  }
  const $ = window.$;
  $(function () {
    $(window).on("scroll", function () {
      if ($(window).scrollTop() > 10) {
        $(".navbar img").attr("src", images + "/logos/type-black.png");
      } else {

        $(".navbar img").attr("src", initialImage);
      }
    });
  });

  return (
    <>
      <nav className="navbar active navbar-expand-lg fixed-top py-4 navbar-light">
        <div className="container">
          <NavLink className="navbar-brand" to={"/"}>
            <img src={initialImage} alt="" height="24" />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item me-3">
                {location.pathname === "/" ? (
                  <Link
                    activeClass="active"
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                    className={`nav-link fw-bold ${
                      !transparent && "transparent"
                    }`}
                    to="services"
                  >
                    Projects
                  </Link>
                ) : (
                  <p
                    className={`nav-link fw-bold ${
                      !transparent && "transparent"
                    }`}
                    onClick={() => navigate("/projects")}
                  >
                    Projects
                  </p>
                )}
              </li>
              <li className="nav-item me-4">
                {location.pathname === "/" ? (
                  <Link
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                    className={`nav-link fw-bold ${
                      !transparent && "transparent"
                    }`}
                    to="about"
                  >
                    About Us
                  </Link>
                ) : (
                  <p
                    className={`nav-link fw-bold ${
                      !transparent && "transparent"
                    }`}
                    onClick={() => navigate("/about")}
                  >
                    About Us
                  </p>
                )}
              </li>
            </ul>
            <motion.a
              className="btn btn-success ms-lg-3 nav-btn py-2 px-4"
              href={"mailto:contact@cidarstudios.com"}
              whileHover={{
                scale: 1.1,
                boxShadow: "0px 0px 8px #20c997",
                transition: { type: "spring", stiffness: 320 },
              }}
            >
              Reach Us
            </motion.a>
          </div>
        </div>
      </nav>
    </>
  );
}
