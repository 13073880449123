import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const images = process.env.PUBLIC_URL + "/assets/images"
    const videos = process.env.PUBLIC_URL + "/assets/video"
export default function Section({ bg, vid, title, desc, hideButton, reverse, isService = false, isProject = false, isProjectDetail = false }) {
    const navigate = useNavigate();

    return (
        <>
            {isService &&
                <div className={`row bg-light p-5 rounded-3 m-5`}>
                    <div className="col-lg-10 mx-auto">
                        <div className={`row ${reverse && 'flex-row-reverse'}`}>
                            <div className="col-lg-6">
                                <div className="">
                                    {bg && <img src={images + bg} alt="" width={'100%'} height={"100%"}/>}
                                    {console.log({vid})}
                                    {vid !== undefined ? <video width={"100%"} height={"100%"} muted loop autoPlay>
                                        <source src={videos + vid} type="video/mp4" />
                                    </video> : null}
                                </div>
                            </div>
                            <div className="col-lg-6 mt-5 pt-5">
                            {!hideButton ? <NavLink className="nav-link fw-bold" to={`/services/${title}`}>
                                    <h4 className="fw-bold title">{title}</h4>
                                </NavLink> :
                                <h4 className="fw-bold title">{title}</h4>
                            }
                                <p>{desc}</p>
                                {!hideButton && <button onClick={() => navigate(`/services/${title}`)} className="btn btn-success py-3 px-4">View More</button>}
                            </div>
                        </div>
                    </div>
                </div>
            }
            {isProject &&
                <div className={`row rounded-3 m-5`}>
                    <div className="col-lg-12 mx-auto">
                        <div className={`row ${reverse && 'flex-row-reverse'}`}>
                            <div className="col-lg-4">
                                <img src={bg} alt="" width={'100%'} height={"600px"} style={{objectFit: "cover"}}/>
                            </div>
                            {/*<div className="col-lg-6 mt-5 pt-5">
                                {isProjectDetail ? (<h4 className="fw-bold">{title}</h4>) :
                                    (<NavLink className="nav-link fw-bold" to={`/projects/${title}`}>
                                        <h4 className="fw-bold title">{title}</h4>
                                    </NavLink>)
                                }
                                <p>{desc}</p>
                                <button onClick={() => navigate(`/projects/${title}`)} className="btn btn-success py-3 px-4">View Project</button>
                            </div>*/}
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
