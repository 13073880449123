import React from "react";

const Subscription = () => {
  return (
    <div className="d-flex justify-content-center">
      <div className="subscription d-flex flex-column align-items-center p-5 rounded-3">
        <h2 className="quote-text">Get a Free Quote</h2>
        <form className="d-flex flex-column align-items-center" action="https://formspree.io/f/mjvdnbzp" method="POST">
            <input
              type="name"
              name="name"
              placeholder="Enter your name"
            />
            <input
              type="email"
              name="email"
              placeholder="Enter your email address"
            />
          <button className="cta-btn" type="submit">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Subscription;
