import React from 'react'
import { motion, useAnimation } from "framer-motion"
import constants from "./utils/constants"
import { useInView } from "react-intersection-observer"
import { useEffect } from "react"

const ServiceHeader = () => {
    const controls = useAnimation()
    const [ref, inView] = useInView()
    useEffect(() => {
        if (inView) {
            controls.start("animate");
        }
    }, [controls, inView]);

    const description = "We help companies sell their products by attracting more customers using stunning product animations and photorealistic 3D renders"
    const images = process.env.PUBLIC_URL + "/assets/images"

  return (
            <div className="text-center col-lg-6 mx-auto">
                <h3>Here's what we can do for you</h3>
                <p>We help companies sell their products by attracting more
                    customers using stunning product animations and photorealistic
                    3D renders
                </p>
            </div>
  )
}

export default ServiceHeader