import { motion, useAnimation } from "framer-motion"
import constants from "./utils/constants"
import { useInView } from "react-intersection-observer"
import { useEffect } from "react"
const videos = process.env.PUBLIC_URL + "/assets/video"
export default function Reel() {
    const controls = useAnimation()
    const [ref, inView] = useInView()
    useEffect(() => {
        if (inView) {
            controls.start("animate");
        }
    }, [controls, inView]);

    return (
        <>
            <motion.div className="container my-5 text-center text-white"
                variants={constants.scrollIn}
                ref={ref}
                initial="initial"
                animate={controls}
            >
                <div className="reel ">
                    <video controls className="rounded-4 vid-width w-100" muted loop autoPlay id="myVideo">
                        <source src={videos + "/reel.mp4"} type="video/mp4" />
                    </video>
                </div>
            </motion.div>
        </>
    )
}
