import { motion, useAnimation } from "framer-motion"
import constants from "./utils/constants"
import { useInView } from "react-intersection-observer"
import { useEffect } from "react"

export default function Testimonials() {
    const images = process.env.PUBLIC_URL + "/assets/images"
    const controls = useAnimation()
    const [ref, inView] = useInView()
    useEffect(() => {
        if (inView) {
            controls.start("animate");
        }
    }, [controls, inView]);
    return (
        <>
            <motion.div className="container my-5 p-5 text-center testimonials-section rounded-4"
                variants={constants.slideIn}
                ref={ref}
                initial="initial"
                animate={controls}
            >
                <div className="px-5">
                    <h1 className="mt-5 pt-5">Testimonials</h1>
                    <small><u>What people say about Cidar</u></small>
                </div>
                <div className="row mt-5 px-5">
                    <div className="col-lg-6 pt-4">
                        <div className="py-5 text-center  px-4 h-100 bg-white rounded-3">
                            <div>
                                <img height={80} width={80} className="rounded-circle" src={`${images}/testimonials/luiz-carranza.jpg`} alt="ciprian"/>
                                <div className="mt-2 ms-3">
                                    <h4 className="mb-0">Luis Carranza</h4>
                                    <small className="mt-0"><u>Founder & CEO at Fayre Labs</u></small>
                                </div>
                            </div>
                            <p className="mt-2">Gideon is a great designer and delivers on time. Quality work.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6 pt-4">
                        <div className="py-5 px-4 h-100 bg-white rounded-3">
                            <div>
                            <img height={80} width={80} className="rounded-circle" src={`${images}/testimonials/ciprian-popescu.jpg`} alt="ciprian"/>
                                <div className="mt-2 ms-3">
                                    <h4 className="mb-0">Ciprian Popescu</h4>
                                    <small className="mt-0"><u>Founder at Mojis</u></small>
                                </div>
                            </div>
                            <p className="mt-2">One of the most amazing 3D animators that I have ever had the privilege to work with. 
                            Thanks so much Gideon for your brilliance and your hard work! 
                            I have many more projects that I am trying to do and you best believe that. will work only with you! Forever and ever! 
                            Thanks again for all your hard work and your talent!
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6 mx-auto pt-4">
                        <div className="py-5 px-4 h-100 bg-white rounded-3">
                            <div>
                            <img height={80} width={80} className="rounded-circle" src={`${images}/testimonials/boredape.jpg`} alt="ciprian"/>
                                <div className="mt-2 ms-3">
                                    <h4 className="mb-0">Sidh Jadhav</h4>
                                    <small className="mt-0"><u>SkillCat</u></small>
                                </div>
                            </div>
                            <p className="mt-2">Gideon delivered on all the milestones perfectly. The communication was great 
                            and he was happy to make multiple updates even 
                            after the deliverable was already created. We would definitely work with him for future projects

                            </p>
                        </div>
                    </div>
                </div>
            </motion.div>
        </>
    )
}
